// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isRazorPayActive: true,
  // RazorPayKey: 'rzp_live_ANPClOvMOkJWJJ',
  // RazorPaySecretKey: 'ToAq8IRcq403KOKhr5vQZjA8',
  // RazorPayMidKey: 'NifRIZRvBzchJL',
  RazorPayKey: 'rzp_test_fLuk0WrrEsCouJ',
  RazorPaySecretKey: 'B7sevCeZ2G0v2ajEcRP8Xkbc',
  RazorPayMidKey: 'NifRIZRvBzchJL',
  RazorPayAccount1: 'acc_LJ3FEPSlTJhqvw',
  RazorPayAccount2: 'acc_LHpJDcl339TN63',
  UniversityTypeId: 3,
  FeeAdmin: {
    username: 'fee-admin',
    password: 'payment-2-student'
  },
  EMPLOYEE_FILTER:{
    DRIVER: 193,
    ATTENDER: 194
  }
};
